import { FC, createContext, useReducer, useState } from 'react';
type GlobalContext = {
  loader: boolean;
  setLoader: (loader: boolean) => void;
  state: State;
  dispatch: React.Dispatch<Action>;
};

type State = {
  focusOnNode: boolean;
  node: string | null;
};

type Action =
  | { type: "SET_FOCUS_ON_NODE"; payload: boolean }
  | { type: "FOCUS_ON_NODE"; payload: string | null };

const initialState: State = {
  focusOnNode: false,
  node: null,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalContext = createContext<GlobalContext>(
  {} as GlobalContext
);

interface Props {
  children: React.ReactNode;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_FOCUS_ON_NODE":
      return {
        ...state,
        focusOnNode: action.payload,
      };
    case "FOCUS_ON_NODE":
      return {
        ...state,
        node: action.payload,
      };
    default:
      return state;
  }
}

export const ContextProvider: FC<Props> = ({ children }) => {
  const [loader, setLoader] = useState<boolean>(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider
      value={{
        loader,
        setLoader,
        state,
        dispatch,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
